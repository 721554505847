import React from 'react';
import { ThemeProvider } from 'styled-components';
import MainWrapper from './components/main-wrapper';
import Section1 from './Layout/section1';
import Section2 from './Layout/section2';
import AppContainer from './Layout/app-container';
import Header from './Layout/header';
import GlobalStyles from './theme/global-styles';
import { useTheme } from './theme/theme-provider';

import 'react-lazy-load-image-component/src/effects/blur.css';

function App() {
  const theme = useTheme();

  return (
    <ThemeProvider theme={{ mode: theme.mode }}>
      <GlobalStyles />
      <AppContainer>
        <MainWrapper>
          <Header />
          <Section1 />
          <Section2 />
        </MainWrapper>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
