import React from 'react';
import styled from 'styled-components';
import { media } from '../theme';
import { useTheme } from '../theme/theme-provider';
import { Themes } from '../theme/types';
import Card from '../components/card';
import Section from '../components/section.component';
import Shape from '../components/shape';
import StravaLogoButton from '../components/strava-logo';

type Props = {
  children?: never;
  className?: string;
};

const Section2 = ({ className }: Props) => {
  const themeState = useTheme();
  const isLight = themeState.mode === Themes.light;

  return (
    <Section {...{ className }} primary>
      {renderFigures(!isLight, 18)}
      <Card primary>
        <Card.Header>JUST login with</Card.Header>
        <StravaLogoButton />
      </Card>
      <Card>
        <p>Give the authorisations</p>
      </Card>
      <Card primary>
        <p>Customize your settings</p>
        <span>Coming soon</span>
      </Card>
    </Section>
  );
};

function renderFigures(isHidden: boolean, amount: number) {
  return isHidden ? null : Array.from({ length: amount }).map((_p, i) => <Shape key={i} />);
}

export default styled(Section2)`
  grid-row: 1 / span 2;
  grid-column: 3 / 3;
  display: flex;
  position: relative;

  .rectangle {
    position: absolute;
    transform: rotate(-30deg);
  }

  .oval {
    position: absolute;
  }

  @media screen and (${media.max.sm}) {
    grid-column: unset;
    grid-row: unset;
  }
`;
