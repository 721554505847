import React from 'react';
import styled from 'styled-components';
import { ReactComponent as _Oval } from '../assets/oval.svg';
import { ReactComponent as Rectangle } from '../assets/rectangle.svg';
import * as u from '../utils';

const Oval = styled<any>(_Oval)`
  fill: ${p => (p.filled === 'true' ? '#ED7936' : '#0000')};
`;

function Shape() {
  const size = u.randomSize();
  const props = {
    height: size,
    width: size,
    style: {
      top: `${u.randomCoordinates()}%`,
      left: `${u.randomCoordinates()}%`,
    },
  };
  const isTrue = u.randomTrue();

  return !isTrue ? (
    <Oval className="oval" {...props} filled={u.randomTrue().toString()} />
  ) : (
    <Rectangle className="rectangle" {...props} />
  );
}

export default Shape;
