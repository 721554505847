import React from 'react';
import styled from 'styled-components';
import { Logo } from '../components/logo';
import ThemeToggler from '../components/theme-toggler';
import { media } from '../theme';
import { useTheme } from '../theme/theme-provider';
import { Themes } from '../theme/types';
import * as t from '../theme';

type Props = {
  children?: never;
  className?: string;
};

const Header = ({ className }: Props) => {
  const themeState = useTheme();
  const isDark = themeState.mode === Themes.dark;

  return (
    <header {...{ className }}>
      <Logo {...{ isDark }} />
      <ThemeToggler />
    </header>
  );
};

function Style<T>(C: (props: T) => JSX.Element) {
  return styled(C)`
    display: grid;
    grid-column: 2 / span 2;
    grid-row: 1 / 1;
    padding: 1.5rem;
    z-index: 100;

    @media screen and (${media.max.sm}) {
      background: ${t.color.white};
      grid-column: unset;
      grid-row: unset;
      padding-bottom: 0;
    }
  `;
}

export default Style(Header);
