import React from 'react';
import styled from 'styled-components';
import * as t from '../theme';

type Props = {
  children?: never;
  className?: string;
};

const Telephone = ({ className }: Props) => {
  return <div {...{ className }} />;
};

const Style = (C: any) => styled(C)<Props>`
  background: url("${t.telUrl}") no-repeat center / contain;
  display: flex;
  height: 100%;
  max-height: 666px;
  max-width: calc(666px / 2);
  width: 100%;
`;

export default Style(Telephone);
