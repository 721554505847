import { useState, useEffect } from 'react';
import { Themes } from '../theme/types';

// Define available themes
export const colorSchemes: { [iterator: string]: string } = {
  [Themes.dark]: '(prefers-color-scheme: dark)',
  [Themes.light]: '(prefers-color-scheme: light)',
};

function useDetectColorScheme() {
  const [scheme, setScheme] = useState<Themes>(Themes.light);

  useEffect(() => {
    if (!window.matchMedia) {
      return;
    }

    // The listener
    const listener = (e: MediaQueryList | MediaQueryListEvent) => {
      if (!e || !e.matches) {
        return;
      }
      const schemeNames = Object.keys(colorSchemes);
      for (let i = 0; i < schemeNames.length; i++) {
        const schemeName = schemeNames[i];
        if (e.media === colorSchemes[schemeName]) {
          setScheme(schemeName as Themes);
          break;
        }
      }
    };

    // Add listener for all themes
    let activeMatches: MediaQueryList[] = [];
    Object.keys(colorSchemes).forEach(schemeName => {
      const mq = window.matchMedia(colorSchemes[schemeName]);
      mq.addListener(listener);
      activeMatches.push(mq);
      listener(mq);
    });

    // Remove listeners, no memory leaks
    return () => {
      activeMatches.forEach(mq => mq.removeListener(listener));
      activeMatches = [];
    };
  }, []);

  return scheme;
}

export default useDetectColorScheme;
