export type ThemeContext = {
  mode: Themes;
  toggle: () => void;
  set: (mode: Themes) => void;
};

export enum Themes {
  light = 'light',
  dark = 'dark',
}
