import React from 'react';
import styled from 'styled-components';
import { media } from '../theme';
import Section from '../components/section.component';
import Telephone from '../components/telephone';
import Weather from '../components/weather';

type Props = {
  children?: never;
  className?: string;
};

const Section1 = ({ className }: Props) => {
  return (
    <Section {...{ className }}>
      <h1>
        Add
        <Weather />
        to your activity line
      </h1>
      <Telephone />
    </Section>
  );
};

export default styled(Section1)`
  grid-column: 2 / 2;
  grid-row: 1 / span 2;

  h1 {
    margin-top: 0;
  }

  @media screen and (${media.max.sm}) {
    grid-column: 1;
    grid-row: unset;
  }
`;
