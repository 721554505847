import styled from 'styled-components';
import * as t from '../theme';

const AppContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  color: ${t.color.text};
  overflow: auto;
  background: ${t.color.bg};
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media screen and (${t.media.max.sm}) {
    align-content: flex-start;
    justify-content: center;
    height: auto;
  }
`;

AppContainer.displayName = 'AppContainer';

export default AppContainer;
