import React from 'react';
import * as t from '../theme';
import styled from 'styled-components';

type Props = {
  children: JSX.Element[] | JSX.Element;
  className?: string;
  primary?: boolean;
};

const Section = ({ children, className }: Props): JSX.Element => {
  return <section {...{ className }}>{children}</section>;
};

const Styled = (C: any) => styled(C)<Props>`
  align-items: center;
  background: ${p => (p.primary ? t.section.bgPrimary : t.section.bg)};
  color: ${p => (p.primary ? t.color.textLight : t.color.text)};
  display: flex;
  flex-flow: column;
  height: clamp(300px, 83vh, 73vw);
  overflow: hidden;
  padding: 5rem 4rem 4rem;
  text-align: center;
  width: clamp(375px, 35vw, 650px);
  justify-content: center;

  @media screen and (${t.media.max.sm}) {
    height: 100%;
    padding: 3rem 1rem;
    width: 100vw;
  }
`;

export default Styled(Section);
