export const size = {
  xs: 480,
  sm: 576,
  md: 768, // ipad y
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

export const media = {
  max: {
    xs: `max-width: ${size.xs - 1}px`,
    sm: `max-width: ${size.sm - 1}px`,
    md: `max-width: ${size.md - 1}px`,
    lg: `max-width: ${size.lg - 1}px`,
    xl: `max-width: ${size.xl - 1}px`,
    xxl: `max-width: ${size.xxl - 1}px`,
  },
  min: {
    xs: `min-width: ${size.xs}px`,
    sm: `min-width: ${size.sm}px`,
    md: `min-width: ${size.md}px`,
    lg: `min-width: ${size.lg}px`,
    xl: `min-width: ${size.xl}px`,
    xxl: `min-width: ${size.xxl}px`,
  },
};
