import React, { useEffect, useState } from 'react';

type WeatherProps = {
  children?: never;
};

const ic = ['☀️', '⛈', '🌡', '🌙', '🌦', '🌤'];

const Weather = (_p: WeatherProps) => {
  const [icon, setIcon] = useState(ic[0]);

  useEffect(() => {
    let i = 1;
    const IId = setInterval(() => {
      setIcon(ic[i]);
      i++;
      i === ic.length && (i = 0);
    }, 600);

    return () => clearInterval(IId);
  }, []);

  return (
    <>
      {' '}
      <span role={'img'} aria-label={'the sun icon'}>
        {icon}
      </span>{' '}
    </>
  );
};

export default Weather;
