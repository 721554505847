import React from 'react';
import { useTheme } from '../theme/theme-provider';
import { Themes } from '../theme/types';
import Switch from './switch';

type ThemeTogglerProps = {
  children?: never;
};

const ThemeToggler = (_p: ThemeTogglerProps) => {
  const themeState = useTheme();
  const checked = themeState.mode === Themes.light;
  const onChange = () => themeState.toggle();
  const uncheckedIcon = (
    <span role="img" aria-label="icon">
      💡
    </span>
  );
  const checkedIcon = (
    <span role="img" aria-label="icon">
      👁
    </span>
  );

  return (
    <Switch
      {...{
        checked,
        onChange,
        checkedIcon,
        uncheckedIcon,
      }}
    />
  );
};

export default ThemeToggler;
