import Switch, { ReactSwitchProps } from 'react-switch';
import styled, { StyledComponent } from 'styled-components';
import React from 'react';
import * as t from '../theme';

const SwitchWrapper = (p: ReactSwitchProps) => {
  return (
    <div className={p.className}>
      <Switch {...p} />
    </div>
  );
};

function Styled(C: (p: ReactSwitchProps) => JSX.Element): StyledComponent<any, any> {
  return styled(C)`
    align-items: center;
    align-self: auto;
    display: flex;
    font-size: 20px;
    grid-row: 1 / 1;
    justify-self: end;

    .react-switch-bg {
      background: ${t.color.white} !important;
      border: ${t.swich.border};
      div {
        display: flex;
        justify-content: center;
        line-height: 25px;
      }
    }
    .react-switch-handle {
      box-shadow: ${t.swich.shadow};
      background: ${t.color.white};
    }
  `;
}

export default Styled(SwitchWrapper);
