import React from 'react';
import styled, { DefaultTheme, StyledComponent } from 'styled-components';
import * as t from '../theme';

type CardProps = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  primary?: boolean;
};

type HeaderProps = {
  children: JSX.Element | JSX.Element[];
};

type TCard = StyledComponent<any, CardProps, DefaultTheme> & {
  Header: StyledComponent<any, HeaderProps, DefaultTheme>;
};

const CardBody = ({ className, children }: CardProps) => {
  return <div className={className}>{children}</div>;
};

const Card = styled(CardBody)<CardProps>`
  align-items: center;
  background: ${p => (p.primary ? t.card.bgPrimary : t.card.bg)};
  border-radius: 0.6rem;
  border: ${p => (p.primary ? '' : t.card.border)};
  box-shadow: ${p => (p.primary ? '' : t.card.shadow)};
  color: ${p => (p.primary ? t.color.textLight : t.color.text)};
  display: flex;
  flex-direction: column;
  height: max(20%, 190px);
  padding: 1.5rem;
  transform: scale(${p => (p.primary ? 1 : 1.1)});
  width: max(40%, 244px);
  z-index: ${p => (p.primary ? 1 : 2)};

  & > p {
    align-self: flex-start;
    border-left: 1px solid ${p => (p.primary ? t.color.white : t.color.primary)};
    color: inherit;
    max-width: 50%;
    padding: 0.7rem 0.6rem;
  }

  span {
    margin-top: 1rem;
    color: ${p => (p.primary ? t.color.text : t.color.secondary)};
    text-decoration: underline;
    font-size: 0.8rem;
  }

  a {
    align-self: center;
  }
` as TCard;

Card.Header = styled.h1``;

export default Card;
