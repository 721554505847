import { Themes } from './types';
import telLight from '../assets/tel-light@2x.png';
import telDark from '../assets/tel-dark@2x.png';
import t from 'styled-theming';

export const telUrl = t('mode', {
  [Themes.light]: telLight,
  [Themes.dark]: telDark,
});

export const color = {
  bg: t('mode', {
    [Themes.light]: '#E7E7E7',
    [Themes.dark]: '#90907D',
  }),
  primary: t('mode', {
    [Themes.light]: '#ED6E2D',
    [Themes.dark]: '#36342C',
  }),
  white: t('mode', {
    [Themes.light]: '#FFFFFF',
    [Themes.dark]: '#90907D',
  }),
  secondary: t('mode', {
    [Themes.light]: '#1CA78B',
    [Themes.dark]: '#36342C',
  }),
  text: t('mode', {
    [Themes.light]: '#5f5f5f',
    [Themes.dark]: '#C8C8BE',
  }),
  textLight: t('mode', {
    [Themes.light]: '#FFFFFF',
    [Themes.dark]: '#C8C8BE',
  }),
};

export const shadow = {
  sm: t('mode', {
    [Themes.light]: '0px 3px 10px #a0020257',
    [Themes.dark]: '0px 0px 5px #6665',
  }),
};

export const swich = {
  shadow: t('mode', {
    [Themes.light]: '0 2px 4px 0 rgba(83, 31, 0, 0.78)',
    [Themes.dark]: '0 2px 4px 0 rgba(45,50,25,0.78)',
  }),
  border: t('mode', {
    [Themes.light]: '1px solid #FFFFFF',
    [Themes.dark]: '1px solid #C8C8BE',
  }),
};

export const card = {
  bg: t('mode', {
    [Themes.light]: '#FFFFFF',
    [Themes.dark]: '#36342C44',
  }),
  bgPrimary: t('mode', {
    [Themes.light]: '#ED6E2D',
    [Themes.dark]: '#36342C',
  }),
  border: t('mode', {
    [Themes.light]: '1px solid #FFFFFF',
    [Themes.dark]: '1px solid #C8C8BE',
  }),
  shadow: t('mode', {
    [Themes.light]: '0 8px 46px #8e381147',
    [Themes.dark]: '#36342C44',
  }),
};

export const section = {
  bg: t('mode', {
    [Themes.light]: '#FFFFFF',
    [Themes.dark]: '#90907D',
  }),
  bgPrimary: t('mode', {
    [Themes.light]: 'linear-gradient(45deg,#A22C2C 0%,#F16521 100%)',
    [Themes.dark]: 'inherit',
  }),
};
