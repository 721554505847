import React, { useEffect } from 'react';
import useDetectColorScheme from '../hooks/useDetectThemeMode';
import { ThemeContext, Themes } from './types';

type ThemeProviderProps = {
  children: JSX.Element | JSX.Element[];
};

const defaultMode = Themes.light;

const ThemeToggleContext = React.createContext<ThemeContext>({
  mode: Themes.light,
  toggle: () => {},
  set: (_mode: Themes) => {},
});

export const useTheme = () => React.useContext(ThemeToggleContext);

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [themeState, setThemeState] = React.useState({
    mode: defaultMode,
  });

  const toggle = () => {
    setThemeState({
      mode: themeState.mode === Themes.light ? Themes.dark : Themes.light,
    });
  };

  const set = (mode: Themes) => {
    setThemeState({ mode });
  };

  const value = {
    mode: themeState.mode,
    toggle,
    set,
  };

  const mode = useDetectColorScheme();

  useEffect(() => {
    set(mode);
  }, [mode]);

  return <ThemeToggleContext.Provider {...{ value }}>{children}</ThemeToggleContext.Provider>;
};

export default ThemeProvider;
