import React from 'react';
import { LazyLoadImageProps } from 'react-lazy-load-image-component';
import styled from 'styled-components';
import src from '../assets/icon.png';
import * as t from '../theme';
import { Image } from './image';

type LogoProps = {
  className?: string;
  isDark?: boolean;
};

const C = ({ className }: LogoProps) => {
  const img: LazyLoadImageProps = {
    src,
    alt: 'Meteology',
    height: 0,
    width: 0,
  };
  return (
    <div {...{ className }}>
      <Image {...img} />
      <h4>Meteology</h4>
    </div>
  );
};
C.displayName = 'Logo';

export const Logo = styled(C)<LogoProps>`
  display: flex;
  align-items: flex-end;
  background: ${p => (p.isDark ? t.color.bg : 'transparent')};
  grid-row: 1 / 1;

  img {
    mix-blend-mode: ${p => (p.isDark ? 'luminosity' : 'normal')};
    margin-right: 0.6rem;
    height: max(30px, 2vw);
    width: max(30px, 2vw);
    filter: drop-shadow(${t.shadow.sm});
    //box-shadow: ${t.shadow.sm};
  }

  h4 {
    margin: 0;
    display: inline-block;
    font-weight: 600;
    color: ${t.color.primary};
    font-size: clamp(1rem, 20px, 2vw);
  }
`;
