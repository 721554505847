import React from 'react';
import styled from 'styled-components';
import { media } from '../theme';

type Props = {
  children: JSX.Element | JSX.Element[];
  className?: string;
};

const MainWrapper = ({ className, children }: Props) => {
  return <div {...{ className }}>{children}</div>;
};

const Styled = (C: any) => styled(C)`
  display: grid;
  border-radius: 1.5rem;
  overflow: hidden;
  grid-template-columns: 1fr min-content min-content 1fr;
  grid-template-rows: min-content 1fr;
  max-height: 91vh;

  @media screen and (${media.max.sm}) {
    border-radius: 0;
    grid: min-content 1fr 1fr / 1fr;
    max-height: 100%;
  }
`;

export default Styled(MainWrapper);
